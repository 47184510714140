<template>
  <section id="footer" class="footer">
    <div class="footer__info">
      <p class="footer__info-text">
        ООО «Автобан Чери» | ОГРН: 1226600044912 ИНН: 6679154153 | КПП:
        667901001
      </p>
      <a @click="togglePrivacy" class="footer__policy"
        >©2022 | Политика конфиденциальности</a
      >
    </div>
    <a @click="expanded = !expanded" class="footer__disclaimer-btn"
      >Дисклеймер</a
    >
    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div id="disclaimer" class="footer__disclaimer" v-if="expanded">
        <p class="footer__disclaimer-text">
          *Срок рассрочки суммируется, исходя из подключенных клиентом не
          позднее даты покупки автомобиля Тарифных планов: «Авто в рассрочку на
          30 месяцев NEW»«Остаточный платеж»», которым предусмотрено подключение
          опции «Остаточный платеж» (далее – ОП) в размере не более 50% от
          стоимости Автомобиля (оплачивается в последний платеж по рассрочке) и
          «Авто в рассрочку на 48 месяцевувеличение срока», предоставляющему
          срок рассрочки на оплату ОП до 48 мес. Тарифные планы подключаются по
          «Карта «Халва» на покупку новых автомобилей марки CHERY 2023 года
          выпуска и доп. оборудования у официальных дилеров.
        </p>
        <p class="footer__disclaimer-text">
          Изображения на картинках могут отличаться от серийных моделей. CHERY
          Центр Автобан будет прилагать все усилия, чтобы обеспечить точность и
          актуальность данных, содержащихся на Сайте, однако CHERY Центр Автобан
          не несет никакой ответственности в отношении любых претензий или
          убытков, понесенных в связи с использованием информации, размещенной
          на Сайте. CHERY Центр Автобан оставляет за собой право в любое время
          вносить изменения в перечень и спецификацию продукции. Для получения
          подробной информации о реализуемых товарах, работах и услугах и их
          цене просьба обращаться к менеджерам CHERY Центр Автобан. При
          проведении рекламных мероприятий в виде акций, специальных
          предложений, предоставлений подарков и т.д. количество подарков, услуг
          ограничено. Действительную информацию о количестве подарков, услуг,
          дате действия и т.д. рекламных мероприятий необходимо уточнять у
          менеджеров CHERY Центр Автобан. Данная страница носит информационный
          характер и ни при каких условиях не является публичной офертой,
          определяемой положениями Статьи 437 ГК РФ. Все сопутствующие услуги
          оказывают компании-партнёры.
        </p>
        <h3 class="footer__disclaimer-title">«Карта «Халва»:</h3>
        <p class="footer__disclaimer-text">
          Клиентам от 20 до 75 лет по паспорту РФ. Обслуживание – 0 руб. ПСК -
          0% годовых при погашении предоставленной рассрочки в течение льготного
          периода кредитования. Ставка в течение льготного периода (36 мес.) -
          0% год. При его нарушении, по окончании – 0,0001% годовых на
          задолженность с даты возникновения. Срок рассрочки от 1 до 30 мес. у
          партнёров. Лимит в течение 5 лет до 500 000 руб., по решению Банка до
          9 000 000 руб. на покупку новых автомобилей марки CHERY 2023, 2024
          года выпуска и дополнительного оборудования в официальных дилерских
          центрах при подключении Тарифного плана «Авто в рассрочку на 18
          месяцев new» или «Авто в рассрочку на 24 месяцев new». Комиссия за
          подключение: <br />
          «Авто в рассрочку на 18 месяцев new» - 1/18 от суммы увеличенного
          лимита в рамках тарифного плана, взимается при несоблюдении условия
          ежемесячного совершения от 5 покупок по «Карта «Халва» на общую сумму
          от 10 000 руб.; «Авто в рассрочку на 24 месяца new*» - 2/24 от суммы
          увеличенного лимита в рамках тарифного плана, где 1/24 взимается
          (безусловно) за увеличение срока рассрочки до 24 мес. и 1/24 взимается
          при несоблюдении вышеуказанных условий о покупках. <br />
          Договором предусмотрено оформление договора страхования автомобиля от
          рисков хищения (угона), утраты (гибели). Залоговое обеспечение – залог
          приобретаемого транспортного средства. Предложение действует до
          31.07.2024. ПАО «Совкомбанк». Генеральная лицензия Банка России № 963
          от 05 декабря 2014 г. Не является публичной офертой.
        </p>
        <p class="footer__disclaimer-text">
          Суммарный период технической поддержки 7 лет (3 года гарантии + 4 года
          дополнительной технической поддержки) или до 200 000 км (100 000 км
          гарантии + 100 000 км поддержки), в зависимости от того, что наступит
          раньше - для моделей CHERY TIGGO 4, TIGGO 4 PRO, TIGGO 7 PRO, TIGGO 7
          PRO MAX, TIGGO 8, TIGGO 8 PRO, TIGGO 8 PRO MAX, ARRIZO 8).
        </p>
        <p class="footer__disclaimer-text">
          Для модели с гибридной силовой установкой TIGGO  7 PRO Plug-in HYBRID,
          TIGGO  8 PRO Plug-in HYBRID суммарный период технической поддержки
          составляет 8 лет (3 года гарантии + 5 лет дополнительной технической
          поддержки) или до 200 000 км (100 000 км гарантии + 100 000 км
          поддержки), в зависимости от того, что наступит раньше.
        </p>
        <p class="footer__disclaimer-text">
          Максимальная выгода складывается из суммы выгоды при участии в
          кредитной программе и программе по трейд-ин или при выгоде по прямой
          скидке. Не оферта.
        </p>
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    togglePrivacy() {
      this.emitter.emit("open-privacy", {
        open: false,
      });
    },
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;
      getComputedStyle(element).height;
      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
  mounted() {
    this.emitter.on("open-privacy", (data) => {
      this.privacyIsHidden = data.open;
    });
  },
};
</script>

<style lang="scss" scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.expand-enter-active,
.expand-leave-active {
  transition: height 0.5s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
